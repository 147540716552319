html, body, #root, .App, .outer-container {
  height: 100%;
}

.App {
  font-family: 'Open Sans', sans-serif;
}

.filters, .program-filters {
  position: fixed;
  border-radius: 5px;
  padding: 20px;
  background-color: white;
}

.filters {
  z-index: 1000;
  width: 210px;
  top: calc(100% - 211px);
  left: 20px;
  .slide-container {
    margin-top: 15px;
    margin-bottom: 15px;
    .slider {
      cursor: pointer;
      width: 100%;
      -webkit-appearance: none;  /* Override default CSS styles */
      appearance: none;
      height: 10px;
      background: #d3d3d3;
      outline: none;
      opacity: 0.7;
      -webkit-transition: .2s;
      transition: opacity .2s;
      border-radius: 3px;
      padding: 0px;
    }
    .slider:hover {
      opacity: 1;
    }
    .slider::-ms-tooltip {
      display: none;
    }
    .slider::-webkit-slider-thumb {
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      background: $map-icon-blue;
      cursor: pointer;
      border-radius: 20px;
    }
    .slider::-ms-track {
      height: 25px !important;
      padding: 0px;
      margin: 0px;
      border-color: transparent;
      border-width: 1px 0;
      color: transparent;
      background: transparent;
    }
    .slider::-ms-thumb {
      border: none;
      &:hover {
        cursor: pointer;
        background: black;
      }
    }
  }
  .current-min-students {
    font-weight: bold;
  }
  select {
    margin-top: 5px;
    margin-bottom: 18px;
  }
}

a {
  color: #3366BB ;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

br {
  line-height: 28px;
}

.program-filters {
  max-width: 600px;
  .drag-handle {
    width: 100%;
    height: 33px;
    cursor: move;
    background-color: $grey-09;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -20px;
    position: absolute;
    font-size: 13px;
    img {
      opacity: .7;
      margin-top: 10px;
      margin-left: 20px;
    }
  }
  h3 {
    margin-top: 30px;
  }
  .total-students {
    position: absolute;
    right: calc(0% + 20px);
    top: 54px;
    font-weight: bold;
    font-size: 14px;
  }
  .leaflet-popup-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    text-align: center;
    width: 18px;
    height: 14px;
    font: 16px/14px Tahoma, Verdana, sans-serif;
    color: $grey-06;
    text-decoration: none;
    font-weight: bold;
    background: transparent;
    opacity: .7;
    &:hover {
      opacity: 1;
    }
  }

  ul {
    padding-left: 0px;
    max-height: 450px;
    overflow: auto;
    li {
      list-style-type: none;
      padding-top: 3px;
      padding-bottom: 3px;
      padding-right: 5px;
      display: flex;
      justify-content: space-between;
      input {
        margin-right: 15px;
        position: relative;
        top: 1px;
        left: 5px;
        float: left;
      }
      .program-name {
        text-align: left;
        max-width: 440px;
        float: left;
      }
      .program-total-students {
        text-align: right;
        font-weight: bold;
      }
    }
    li:nth-child(odd) {
      background-color: $grey-09;
    }
  }
  button {
    border: 2px solid rgba(0,0,0,0.6);
    background-color: white;
    box-shadow: 2px 2px 2px $grey-09;
    background-clip: padding-box;
    padding: 7px 15px 7px 15px;
    border-radius: 3px;
    margin-right: 15px;
    cursor: pointer;
    &:hover {
      border: 2px solid $grey-04;
      box-shadow: 0px;

    }
  }
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: .4;
  position: fixed;
  z-index: 1000;
  transition: opacity 250ms;
  .loader {
    border: 10px solid $grey-07;
    border-top: 10px solid #3366BB;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    animation: spin 1s linear infinite;
    position: absolute;
    left: calc(50% - 42.5px);
    top: calc(50% - 42.5px);
    transition: opacity 250ms;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}