.leaflet-tooltip-pane {
  .leaflet-tooltip-top {
    padding: 2px 7px 1px 7px;
  }
}

.leaflet-popup-pane {
  .leaflet-popup-content-wrapper {
    border-radius: 5px;
    .leaflet-popup-content {
      margin: 3px 3px 6px 3px;
      .total-students {
        background-color: $nps-blue;
        color: white;
        padding: 5px 0px 5px 10px;
        margin-bottom: 5px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
      }
      width: auto;
      .table-container {
        max-height: 500px;
        overflow: auto;
      }
      table {
        border-spacing: 0px;
        thead {
          font-weight: bold;
          tr {
            th {
              margin: 0px;
              padding-bottom: 5px;
            } 
          }
        }
        th, td {
          padding: 2px 10px 2px 10px;
        }
        tbody {
          tr:nth-child(odd) {
            background-color: $grey-09;
          }
        }
      }
    }
  }
  .leaflet-popup-close-button {
    margin-top: 6px;
    margin-right: 7px;
  }  
}

.hidden {
  display: none;
}