$grey-01: #000000;
$grey-04: #353535;
$grey-05: #282828;
$grey-06: #595959;
$grey-07: #a8a8a8;
$grey-08: #c9c9c9;
$grey-09: #f2f2f2;
$grey-10: rgba(255,255,255,0.5);
$sidebar-grey: #e0e0e0;
$button-grey: #dddddd;

$nps-blue: #00457C;
$map-icon-blue: #3388FF;